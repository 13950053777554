import React from 'react';
// components
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Experience from './components/Experience';
// Proxy Url

const App = () => {
  return (
    <BrowserRouter>
      {/* <div className='bg-site bg-no-repeat bg-cover overflow-hidden'> */}
      <div className='bg-black bg-no-repeat bg-cover overflow-hidden'>
      <Header />
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Banner />} />
      </Routes>
      <Nav />
      <About />
      <Experience />
      <Services />
      <div className='h-[200px]'></div>
      <Skills/>
      <div className='h-[200px]'></div>
      <Work />
      <div className='h-[200px]'></div>
      <Contact csrfToken="{{ csrf_token }}"/>
      {/* <div className='h-[4000px]'></div> */}
      </div>
      </BrowserRouter>
  );
};

export default App;
