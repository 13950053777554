import React from 'react';
// images:
// import Logo from '../assets/logo.svg';
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
  return <header className='py-4'>
    <div className="container mx-auto">
      <div className="flex justify-between items-center">
        {/* Logo */}
        <a href='#' className='bg-slate-50 shadow-2xl rounded-full w-20'>
          {/* <img src={Logo} alt=''/> */}
          {/* <h2 className='text-xl font-bold text-violet-700 mx-3'>En.Wa</h2> */}
          <h2 className='text-xl font-bold text-accent mx-3'>En.Wa</h2>
        </a>
        {/*button*/ }
        <ScrollLink to="contact" smooth={true} duration={500}>
        <button className='btn btn-sm'>Contact me</button>
        </ScrollLink>
      </div>
    </div>
  </header>;
};

export default Header;
