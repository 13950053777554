import React from 'react';
// type animation
import {TypeAnimation} from 'react-type-animation'
//motion
import { motion } from "framer-motion";
//variants
import { fadeIn } from "../variants";
import { Link as ScrollLink } from 'react-scroll';
//image
// import Image from '../assets/1.JPG';
import Image from '../assets/download.gif';
import Socials from './Socials';


const Banner = () => {
  return ( <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center mb-40 ' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          {/* text */}
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1 variants={fadeIn('up',0.3)} initial='hidden' whileInView={'show'} viewport={{once: false,amount: 0.7}} className='text-[55px] font-bold leading-[0.8] lg:text-[110px]'>Ennouri <span>Walid</span></motion.h1>
            <motion.div variants={fadeIn('up',0.4)} initial='hidden' whileInView={'show'} viewport={{once: false,amount: 0.7}} className='mb-6 text-[36px] lg:text-[60px] font-secondary font-semibold uppercase leading-[1]'>
              <span className='text-white mr-4'>I am<br/></span>
              <TypeAnimation sequence={[
                'a Software Developer',2000,
                'an Industrial engineer',2000
              ]}
              speed={50}
              className='text-accent'
              wrapper='span'
              repeat={Infinity}
              />
            </motion.div>
            <motion.p variants={fadeIn('up',0.5)} initial='hidden' whileInView={'show'} viewport={{once: false,amount: 0.7}} className='mb-8 max-w-lg mx-auto lg-mx-0'>Industrial engineer turned web developer. 
            Dive into my portfolio to see my diverse skills and impactful projects.
            </motion.p>
            <motion.div variants={fadeIn('up',0.6)} initial='hidden' whileInView={'show'} viewport={{once: false,amount: 0.7}} className='flex max-w-max gap-x-6 items-center mb-3
            mx-auto lg:mx-0'>
              <ScrollLink to="contact" smooth={true} duration={500}>
                <button className='btn btn-lg'>
                  Contact me
                </button>
              </ScrollLink>
              <a href='https://i.postimg.cc/5yP5ykjN/CV-PFA.png' target='_blank' className='text-gradient btn-link'>My Curriculum vitae</a>
            </motion.div>
            {/* socials */}
            <Socials/>
          </div>

          {/* image */}
          <motion.div variants={fadeIn('left',0.5)} initial='hidden' whileInView={'show'} className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'>
            <img src={Image} alt=''/>
          </motion.div>
        </div>
      </div>
  </section>
  );
};

export default Banner;
