import React, { useState } from 'react';

const Skills = () => {
  const [activeTab, setActiveTab] = useState('Technical');

//   const skills = {
//     Informatique: [
//       { name: 'Data Anaylste', percentage: 90 },
//       { name: 'HTML5/CSS3', percentage: 80 },
//       { name: 'Python', percentage: 90 },
//       { name: 'React JS', percentage: 60 },
//       { name: 'Django', percentage: 90 },
//       { name: 'C', percentage: 80},
//       { name: 'Javascripts', percentage: 70},
//     ],
//     Technical: [
//       { name: 'Gestion Maintenance', percentage: 85 },
//       { name: 'Gestion de production', percentage: 75 },
//       { name: 'Gestion de qualité', percentage: 75 },
//       { name: 'Logistic Industriel', percentage: 65 },
//     ],
//     Management: [
//       { name: 'Lean 6 Sigma', percentage: 90 },
//       { name: 'PDCA / DMAIC', percentage: 90 },
//       { name: 'HAZOP', percentage: 80 },
//       { name: 'APR/ADD', percentage: 70 },
//       { name: '5S', percentage: 90 },
//       { name: 'SMED', percentage: 80 },
//     ],
//   };

const skills = {
    Technical: [
      { name: 'Process Optimization', percentage: 80, description: 'Skills related to optimizing industrial processes for efficiency and productivity.' },
      { name: 'Quality Management', percentage: 85, description: 'Knowledge and abilities related to ensuring product quality and compliance with standards.' },
      { name: 'Supply Chain Management', percentage: 75, description: 'Understanding and managing the flow of materials, information, and finances from suppliers to customers.' },
      { name: 'Industrial Automation', percentage: 70, description: 'Proficiency in utilizing automation technologies to streamline processes and improve efficiency.' },
      { name: 'Manufacturing Systems', percentage: 80, description: 'Knowledge of manufacturing systems design, operation, and improvement.' },
      { name: 'Industrial Safety', percentage: 90, description: 'Understanding and implementation of safety protocols and regulations in industrial settings.' },
      { name: 'Engineering Design', percentage: 85, description: 'Proficiency in CAD software and design principles for developing and improving industrial systems and products.' }
    ],
    Managerial: [
      { name: 'Project Management', percentage: 75, description: 'Ability to plan, execute, and oversee projects within budget and schedule constraints.' },
      { name: 'Team Leadership', percentage: 80, description: 'Skills in leading and motivating teams to achieve project objectives.' },
      { name: 'Resource Allocation', percentage: 75, description: 'Effective allocation of resources such as manpower, materials, and equipment.' },
      { name: 'Cost Control', percentage: 70, description: 'Knowledge and techniques for controlling costs while maintaining quality and productivity.' },
      { name: 'Risk Management', percentage: 80, description: 'Identifying potential risks and implementing strategies to mitigate them.' },
      { name: 'Decision Making', percentage: 85, description: 'Making informed decisions based on data analysis and critical thinking.' },
      { name: 'Communication', percentage: 90, description: 'Clear communication with stakeholders including team members, clients, and management.' }
    ],
    Analytical: [
      { name: 'Data Analysis', percentage: 85, description: 'Ability to analyze data to identify trends, patterns, and opportunities for improvement.' },
      { name: 'Statistical Analysis', percentage: 80, description: 'Proficiency in statistical tools and techniques for analyzing and interpreting data.' },
      { name: 'Simulation Modeling', percentage: 75, description: 'Creating and analyzing simulation models to optimize processes and predict outcomes.' },
      { name: 'Problem-solving', percentage: 90, description: 'Applying analytical and logical thinking to solve complex problems in industrial settings.' },
      { name: 'Continuous Improvement', percentage: 85, description: 'Commitment to continuous improvement through techniques such as Lean and Six Sigma.' },
      { name: 'Root Cause Analysis', percentage: 80, description: 'Identifying the underlying causes of problems to implement effective solutions.' },
      { name: 'Critical Thinking', percentage: 90, description: 'Evaluating information and making reasoned judgments to solve problems and make decisions.' }
    ]
  };
  

  return (
    <div className="container mx-auto px-4 py-8" id="skills">
      <div className="flex justify-center mb-8">
        {Object.keys(skills).map((skill) => (
          <button
            key={skill}
            className={`px-4 py-2 text-lg font-bold border-b-4 ${activeTab === skill ? 'border-yellow-500 text-yellow-500' : 'border-transparent text-gray-500'}`}
            onClick={() => setActiveTab(skill)}
          >
            {skill}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
        {skills[activeTab].map((skill, index) => (
          <div key={index} className="bg-gray-800 rounded-lg shadow-lg p-4 relative group transform transition-transform duration-200 hover:bg-blue-800 hover:scale-105">
            <div>{skill.name}</div>
            <div className="w-full h-2 bg-gray-600 rounded-full mt-2">
              <div
                className="h-full bg-yellow-500 rounded-full animate-slide"
                style={{ width: `${skill.percentage}%` }}
              ></div>
            </div>
            <div className=" transition-opacity duration-200 mt-4 text-gray-300">
              {skill.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Skills;