import React, { useState } from 'react';
// motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
// Data
import Data from './Data';

const Work = () => {
  const [showAllCertificates, setShowAllCertificates] = useState(false);

  const toggleCertificates = () => {
    setShowAllCertificates(!showAllCertificates);
  };

  return (
    <section className='section py-7' id='work'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row gap-x-10'>
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex flex-col gap-y-12 mb-10 lg:mb-0'>
            {/* text */}
            <div>
              <h2 className='h2 leading-tight text-accent'>My Latest <br />
                Certificates
              </h2>
              <p className='max-w-sm mb-16'>My certifications demonstrate my dedication to mastering skills and staying current in my field, 
              ensuring I deliver top-notch solutions.</p>
              <button className='btn btn-sm' onClick={toggleCertificates}>
                {showAllCertificates ? 'Show Less Certificates' : 'View All Certificates'}
              </button>
            </div>
          </motion.div>
          {/* work */}
          <Data showAllCertificates={showAllCertificates} />
        </div>
      </div>
    </section>
  );
};

export default Work;
