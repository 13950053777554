import React, { useState, useEffect } from 'react';
// icon
import {BsArrowUpRight} from 'react-icons/bs';
//motion
import { motion } from 'framer-motion';
//variants
import {fadeIn} from '../variants'


const Services = () => {

  const [projet, setProjet] = useState([]);

  useEffect(() => {
      getProjet();
    }, []);

  const getProjet = async () => {
    let response = await fetch('https://enwa-7d0195e1e041.herokuapp.com/api/projet/');
    let data_projet = await response.json();
    setProjet(data_projet);
  };

  return ( 
  <section className='section py-10' id='services'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row'>
        {/* text  and image*/}
        <motion.div 
        variants={fadeIn('right',0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1 lg:bg-services lg:bg-bottom bg-no-repeat
        mix-blend-lighten mb-12 lg:mb-0'>
          <h2 className='h2 text-accent mb-6' >What I Do.</h2>
          <h3 className='h3 max-w-[455px] mb-16'>I am an Industrial engineer as well as web developer</h3>
          <button className='btn btn-sm'>See my Work</button>
        </motion.div>
        {/* services */}
        <motion.div 
        variants={fadeIn('left',0.5)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1'>
          {/* services list*/}
          <div>
            {projet.map((proj,index)=> {
              return (
                <div 
                className='border-b border-b/20 h-[146px] mb-[38px]
                flex'
                key={index}>
                  <div className='max-w-[476px]'>
                    <h4 className='text-[20px] tracking-wider font-primary
                    font-semibold mb-7'>{proj.title}</h4>
                    <p className='font-secondary leading-tight'>{proj.info}</p>
                  </div>
                  <div className='flex flex-col flex-1 items-end'>
                    <a href={proj.url_report} className='btn w-9 h-9 mb-[42px] flex justify-center items-center'>
                      <BsArrowUpRight/>
                    </a>
                    <a href={proj.url_report} className='text-gradient text-sm'>
                      Learn more
                    </a>
                  </div>
                </div>
              )
            }
            )}
          </div>
        </motion.div>
      </div>
    </div>
  </section>
  );
};

export default Services;
