import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Data = ({ showAllCertificates }) => {
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showAllCertificates) {
      // If showAllCertificates is true, display all certificates
      setDisplayedData(data);
    } else {
      // Otherwise, display a limited number (e.g., 4)
      setDisplayedData(data.slice(0, 4));
    }
  }, [showAllCertificates, data]);

  let getData = async () => {
    try {
      let response = await fetch('https://enwa-7d0195e1e041.herokuapp.com/api/certif/');
      let data = await response.json();
      // console.log(data);
      setData(data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
      {displayedData.map((certif, index) => (
        <div key={index} className='border border-gray-300 rounded-lg overflow-hidden shadow-md'>
          <motion.div
            variants={fadeIn('left', 0.2)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='relative group'>
            {/* Image */}
            <div className='group'>
              <img
                src={certif.url}
                alt={certif.name}
                className='w-full h-auto sm:w-64 sm:h-64 md:w-56 md:h-56 group-hover:scale-125 transition-all duration-500'
              />
              {/* Overlay */}
              <div className='w-full h-full absolute bg-black/70 top-0 left-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                {/* Name and Organism */}
                <div className='absolute inset-0 flex flex-col justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300'>
                  <span className='text-3xl text-gradient'>{certif.organisme}</span>
                  <span className='text-gradient text-xs text-white'>
                    <a href={certif.url} target='_blank' rel='noopener noreferrer'>
                      {certif.name}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default Data;
