import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';

const Experience = () => {
  const [formation, setFormation] = useState([]);
  const [exp, setExp] = useState([]);

  useEffect(() => {
    getFor();
    getExp();
  }, []);

  const getFor = async () => {
    let response = await fetch('https://enwa-7d0195e1e041.herokuapp.com/api/formation/');
    let data_form = await response.json();
    // console.log('Formation Data:', data_form); // Ajout de cette ligne
    setFormation(data_form);
  };
  
  const getExp = async () => {
    let response = await fetch('https://enwa-7d0195e1e041.herokuapp.com/api/experience/');
    let data_exp = await response.json();
    // console.log('Experience Data:', data_exp); // Ajout de cette ligne
    setExp(data_exp);
  };

  const experienceCardClasses =
    'text-gradient shadow-lg rounded-lg p-6 mb-6 transition-transform transform hover:scale-105';

  // Fonction pour extraire l'année d'une date
  const getYearFromDate = (date) => {
    return new Date(date).getFullYear();
  };

  return (
    <section className='section py-7' id='experience'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row lg:items-center lg:gap-10'>
          {/* Experiences */}
          <motion.div
            variants={fadeIn('left', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            <h2 className='h2 text-accent' id='experience-heading'>
              My Experiences
            </h2>
            {/* Add your experience content here */}
            {exp.map((experience, index) => (
              <div className={experienceCardClasses} key={index}>
                <h3 className='text-xl font-semibold'>{experience.title}</h3>
                <h4 className='text-lg font-medium text-white'>
                  {experience.company}
                </h4>
                <p className='text-gray-300'>
                  {experience?.date_début} - {experience?.date_fin}
                </p>
                <p className='text-white'>{experience.info}</p>
              </div>
            ))}
          </motion.div>
          {/* Formations */}
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'>
            <h2 className='h2 text-accent' id='formations-heading'>
              My Training
            </h2>
            {/* Add your formation content here */}
            {formation.map((form, index) => (
              <div className={experienceCardClasses} key={index}>
                <h3 className='text-xl font-semibold'>{form.name}</h3>
                <h4 className='text-lg font-medium text-white'>
                  {form.organisme}
                </h4>
                <p className='text-white'>
                  {getYearFromDate(form.date_début)} -{' '}
                  {getYearFromDate(form.date_fin)}
                </p>
              </div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
