import React,{useState,useEffect} from 'react';
// countup
import CountUp from 'react-countup';
// intersection observer hook

//motion
import { motion } from 'framer-motion';
// variant
import { fadeIn } from '../variants';
import { Link as ScrollLink } from 'react-scroll';


// Function to handle the counting animation
const Navig = ({ endValue }) => {
  const [countStarted, setCountStarted] = useState(false);

  useEffect(() => {
    if (!countStarted) {
      setTimeout(() => {
        setCountStarted(true);
      }, 1000); // Delay for 1 second before starting the animation
    }
  }, [countStarted]);

  return countStarted ? <CountUp start={0} end={endValue} duration={5} /> : null;
};

const currentYear = new Date().getFullYear();
const endValue = currentYear - 2003;



const About = () => {
  return (
  <section className='py-16 lg:section mb-28' >
    <div className='container mx-auto'>
      <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center
      lg:gap-x-0 lg:gap-y-0 h-screen'>
        {/* img */}
        <motion.div
        variants={fadeIn('right',0.3)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1 bg-about bg-contain bg-no-repeat h-[640px]
        mix-blend-lighten bg-top '></motion.div>
        {/* text */}
        <motion.div 
        variants={fadeIn('left',0.5)}
        initial='hidden'
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1'>
          <h2 className='h2 text-accent' id='about'>About me.</h2>
          <h3 className='h3 mb-4'>I am an Industrial engineer as well as web developer</h3>
          <p className='mb-6'>I am a multifaceted professional, blending the worlds of industrial engineering and web development.
           With a solid foundation in engineering principles and a deep passion for web technologies,
            I offer a distinctive fusion of analytical thinking and creative design expertise to all my endeavors.
            Whether it involves optimizing intricate processes within the industrial realm or crafting elegant and functional websites in the digital sphere,
            I thrive on tackling challenges and delivering innovative solutions. Welcome to my portfolio, where you can delve into
            my diverse skill set and explore the impactful projects I've meticulously crafted.
          </p>
          {/* STATS */}
          <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
              <Navig endValue={endValue} /> 
              </div>
              <div className='font-primary text-sm 
              tracking-[2px]'>
              Years  <br/>
              old
              </div>
            </div>

            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
              <Navig endValue={endValue-18} />
              </div>
              <div className='font-primary text-sm 
              tracking-[2px]'>
              Years of <br/>
              Experience
              </div>
            </div>

            <div>
              <div className='text-[40px] font-tertiary text-gradient mb-2'>
              <Navig endValue={5} />
              </div>
              <div className='font-primary text-sm 
              tracking-[2px]'>
              Years of Industrial <br/>
              Training
              </div>
            </div>

          </div>
          <div className='flex gap-x-8 items-center'>
            {/* <button className='btn btn-lg'>Contact me</button> */}
            <ScrollLink to="contact" smooth={true} duration={500}>
            <button className='btn btn-sm'>Contact me</button>
            </ScrollLink>
            <a href='#' className='text-gradient btn-link'>My Portfolio</a>
          </div>
        </motion.div>
      </div>
    </div>
    </section>
    );
};

export default About;

