import React from 'react'
import {FaGithub, FaInstagram, FaLinkedin, FaTwitter,FaMicrosoft} from 'react-icons/fa'
import { motion } from "framer-motion";
import { fadeIn } from "../variants";


const Socials = () => {
  return (
    <motion.div variants={fadeIn('up',0.7)} initial='hidden' whileInView={'show'} viewport={{once: true,amount: 0.7}} className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0' > 
              <a href="https://www.linkedin.com/in/walid-ennouri" target="_blank" rel="noopener noreferrer">
                <FaLinkedin/>
              </a>
              <a href='https://github.com/walid-ennouri' target="_blank" rel="noopener noreferrer">
                <FaGithub/>
              </a>
              <a href='https://www.instagram.com/ennouri_walid/' target="_blank" rel="noopener noreferrer">
                <FaInstagram/>
                </a>
              <a href='https://twitter.com/EnnouriWal8565' target="_blank" rel="noopener noreferrer">
                <FaTwitter/>
              </a>
              <a href='https://learn.microsoft.com/fr-fr/users/walidennouri-1813/' target="_blank" rel="noopener noreferrer">
                <FaMicrosoft/>
              </a>
            </motion.div>
  )
}

export default Socials