import React, { useState } from 'react';
//motion
import { motion } from 'framer-motion';
// variants
import { fadeIn } from '../variants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const Contact = ({ csrfToken }) => {
  const initialFormData = { name: '', email: '', message: '' };

  const [formData, setFormData] = useState(initialFormData);
  const [isSending, setIsSending] = useState(false); // Add this state variable

  const sendMessage = async (e) => {
    e.preventDefault();
    setIsSending(true); // Hide the button when sending the message
    console.log("formData:", formData);
    try {
      const res = await fetch('https://enwa-7d0195e1e041.herokuapp.com/api/send-message/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken
        },
        body: JSON.stringify(formData)
      });

      if (res.ok) {
        console.log('Message sent successfully');
        toast.success('Message sent successfully');
        resetForm();
        window.scrollTo({
          top: document.getElementById('about').offsetTop,
          behavior: 'smooth',
        });
      } else {
        console.error('Failed to send message');
        toast.error('Failed to send message');
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsSending(false); // Show the button again after sending the message
    }
  }

  const resetForm = () => {
    setFormData(initialFormData);
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  }



  return (
    <section className='py-16 lg:section' id='contact'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          {/* Text */}
          <motion.div
            variants={fadeIn('down', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 flex justify-start items-center'
          >
            <div>
              <h4 className='text-xl uppercase text-accent font-medium mb-2 tracking-wide'>Get in touch</h4>
              <h2 className='text-[45px] lg:text-[90px] leading-none mb-12'>Let's work <br /> together!</h2>
            </div>
          </motion.div>
          {/* Form */}
          <motion.form
            variants={fadeIn('up', 0.3)}
            initial='hidden'
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            onSubmit={sendMessage} // Use onSubmit for form submission
            className='flex-1 border rounded-2xl flex flex-col gap-y-6 pb-24 p-6 items-start bg-gradient-to-br from-[#1f1f1f] to-[#2f2f2f] shadow-lg'
          >
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='text'
              placeholder='Your name'
              name='name'
              value={formData.name}
              onChange={handleInputChange}
            />
            <input
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all'
              type='email'
              placeholder='Your email'
              name='email'
              value={formData.email}
              onChange={handleInputChange}
            />
            <textarea
              className='bg-transparent border-b py-3 outline-none w-full placeholder:text-white focus:border-accent transition-all resize-none mb-12'
              placeholder='Your message'
              name='message'
              value={formData.message}
              onChange={handleInputChange}
            />
            {!isSending && <button className='btn btn-lg'>Send message</button>} {/* Only show the button if not sending */}
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
